import React, { useState } from 'react';
import Layout from '@accrosoft-ltd/accropress-theme-jane/src/components/layout';
import { Helmet } from 'react-helmet';

import Modal from 'react-responsive-modal';

import 'react-responsive-modal/styles.css';

import SEO from '@accrosoft-ltd/accropress-websites-components/dist/esm/seo';

import '@accrosoft-ltd/accropress-theme-jane/src/components/wig-bundle.scss';

import { returnResizedImageURL } from '@accrosoft-ltd/accropress-websites-components/dist/esm/imgUtils';

import NoticesSlickSlider from '@accrosoft-ltd/accropress-theme-jane/src/components/NoticesSlickSlider';

import buildMediaContent from '@accrosoft-ltd/accropress-websites-components/dist/esm/buildMediaContent';

import loadable from '@loadable/component';

import Marquee from 'react-double-marquee';

const WeducNotices = loadable(() =>
  import('@accrosoft-ltd/accropress-websites-components/dist/esm/weducNotices')
);
const WigLoader = loadable(() =>
  import('@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader')
);
const WeducNewsFeed = loadable(() =>
  import('@accrosoft-ltd/accropress-websites-components/dist/esm/weducNewsfeed')
);
const WeducDairyDates = loadable(() =>
  import(
    '@accrosoft-ltd/accropress-websites-components/dist/esm/weducDairyDates'
  )
);

var Scroll = require('react-scroll');
var Element = Scroll.Element;
var scroller = Scroll.scroller;

require('es6-promise').polyfill();
var moment = require('moment');

export function NoticeTemplate(props) {
  return (
    <span className="noticesContent">
      {props.data.attachment &&
      props.data.attachment[0] &&
      props.data.attachment[0].external_url ? (
        <a
          href={props.data.attachment[0].external_url}
          title={props.data.attachment[0].title}
          target="_blank"
        >
          <strong className="title">
            {props.data.title.replace(/&nbsp;/g, '').replace(/&pound;/g, '£')}
          </strong>
          <div dangerouslySetInnerHTML={{ __html: props.data.message }}></div>
        </a>
      ) : (
        <>
          <strong className="title">
            {props.data.title.replace(/&nbsp;/g, '').replace(/&pound;/g, '£')}
          </strong>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: props.data.message }}
          ></div>
        </>
      )}
    </span>
  );
}

export function NoticeTemplateTicker(props) {
  return (
    <span>
      {props.data.attachment &&
      props.data.attachment[0] &&
      props.data.attachment[0].external_url ? (
        <a
          href={props.data.attachment[0].external_url}
          title={props.data.attachment[0].title}
          target="_blank"
          rel="noreferrer noopener"
        >
          <strong>
            {props.data.title.replace(/&nbsp;/g, '').replace(/&pound;/g, '£')}
            {props.data.snippet
              .replace(/&nbsp;/g, '')
              .replace(/&pound;/g, '£') && ': '}
          </strong>
          {props.data.snippet.replace(/&nbsp;/g, '').replace(/&pound;/g, '£')}
        </a>
      ) : (
        <>
          <strong>
            {props.data.title.replace(/&nbsp;/g, '').replace(/&pound;/g, '£')}
            {props.data.snippet
              .replace(/&nbsp;/g, '')
              .replace(/&pound;/g, '£') && ': '}
          </strong>
          {props.data.snippet.replace(/&nbsp;/g, '').replace(/&pound;/g, '£')}
        </>
      )}
    </span>
  );
}

export default function HomepagePagePage({
  pageContext: {
    integratorData,
    contentMetaData,
    contentDataData,
    navResultData,
    pluginOptions,
  },
  props,
}) {
  const config = pluginOptions;
  console.log(config);

  const [toggled, setToggled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const [noticesMainLoaded, setNoticesMainLoaded] = useState(false);
  const [noticesEmergencyLoaded, setNoticesEmergencyLoaded] = useState(false);
  const [newsfeedLoaded, setNewsfeedLoaded] = useState(false);
  const [calendarEventsLoaded, setCalendarEventsLoaded] = useState(false);

  const [noticesBannerExpanded, setNoticesBannerExpanded] = useState(false);

  const checkContent = (content) => {
    let div = document.createElement('div');
    div.innerHTML = content;

    var image = div.getElementsByTagName('img')[0];

    if (image && !image.getAttribute('src').includes('external')) {
      image.remove();
    }

    return div.outerHTML;
  };

  console.log('integrators data: ');
  console.log(integratorData);

  console.log('content meta data: ');
  console.log(contentMetaData);

  console.log('content data data: ');
  console.log(contentDataData);

  const updateAndShowModal = (title, description, postType, attachment) => {
    setModalData({
      title: title,
      description: description,
      postType: postType,
      attachment: attachment,
    });
    setModalOpen(true);
  };

  const scrollToContent = (location, offset) => {
    scroller.scrollTo(location, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: offset || 50,
    });
  };

  const toggleNoticesBanner = function () {
    if (noticesBannerExpanded) {
      setNoticesBannerExpanded(false);
    } else {
      setNoticesBannerExpanded(true);
    }
  };

  return (
    <Layout
      {...props}
      navResultData={navResultData}
      contentDataData={contentDataData}
      integratorData={integratorData}
      contentMetaData={contentMetaData}
      pluginOptions={pluginOptions}
    >
      <SEO
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
      />

      {contentDataData && contentDataData.length > 0 ? (
        <div className="main-page-wrapper loaded">
          <div
            className="banner"
            style={{
              backgroundImage: `linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.1)
              ),
              url("${
                contentDataData[0].children[3] &&
                contentDataData[0].children[3].value
                  ? contentDataData[0].children[3].value
                  : 'banner.jpg'
              }")`,
            }}
          >
            <div className="container">
              <div className="bannerWrapper">
                <div className="bannerText">
                  {contentDataData[0].children[0] &&
                    contentDataData[0].children[0].value && (
                      <h1>{contentDataData[0].children[0].value}</h1>
                    )}
                  {contentDataData[0].children[1] &&
                    contentDataData[0].children[1].value && (
                      <h2>{contentDataData[0].children[1].value}</h2>
                    )}
                  <div className="bannerTextWrap">
                    {contentDataData[0].children[2] &&
                      contentDataData[0].children[2].value && (
                        <WigLoader
                          weducWebsitesID={config.weducWebsitesID}
                          weducContainer={config.weducContainerID}
                          weducEntity={config.weducEntityID}
                          contentDataData={contentDataData}
                          contentMetaData={contentMetaData}
                          contentDataArray="0"
                          contentDataChild="2"
                          AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                          AccroPressLocalEndpoint={
                            config.AccroPressLocalEndpoint
                          }
                          integratorID={config.integratorID}
                          integratorAccessToken={config.integratorAccessToken}
                          imageFormatting={
                            process.env.IMAGE_FORMATTING_SECRET ||
                            process.env.GATSBY_IMAGE_FORMATTING_SECRET
                          }
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="header-down-container">
              <div className="row">
                <div className="col-12">
                  <div className="arrow-container">
                    <div className="arrow-down">
                      <div id="scrollToContentFromHero">
                        <button
                          activeclass="active"
                          className="btn scroll"
                          type="submit"
                          value="Scroll Here"
                          onClick={() => scrollToContent('scrollHere', -140)}
                        >
                          <svg
                            version="1.1"
                            viewBox="0 0 129 129"
                            enableBackground="new 0 0 129 129"
                          >
                            <g>
                              <path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"></path>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`banner-notices-container banner-notices-container-type-${
                config.noticesType || 'ticker'
              }`}
            >
              {config.noticesType === 'popup' ? (
                <div
                  id="weduc_notices_home_announcements_panel_wrapper"
                  className={`banner-inner weducWidgetDisplay_${noticesMainLoaded}`}
                >
                  {toggled && (
                    <div id="weduc_notices_home_announcements_panel_wrapper__content">
                      <div
                        className="weduc_notices_home_announcements_panel_wrapper__content__openclose"
                        onClick={() => setToggled(false)}
                      >
                        <span>X</span>
                      </div>

                      <NoticesSlickSlider
                        config={config}
                        setNoticesMainLoaded={setNoticesMainLoaded}
                        noticesMainLoaded={noticesMainLoaded}
                      />
                    </div>
                  )}

                  <div
                    id="weduc_notices_home_announcements_panel_wrapper__bell"
                    onClick={() => setToggled(true)}
                  >
                    <div className="icon">
                      <svg
                        version="1.1"
                        x="0px"
                        y="0px"
                        width="50px"
                        height="30px"
                        viewBox="0 0 50 30"
                        enableBackground="new 0 0 50 30"
                      >
                        <g className="bell-icon__group">
                          <path
                            className="bell-icon__ball"
                            id="ball"
                            fillRule="evenodd"
                            strokeWidth="1.5"
                            clipRule="evenodd"
                            fill="none"
                            stroke="#currentColor"
                            strokeMiterlimit="10"
                            d="M28.7,25 c0,1.9-1.7,3.5-3.7,3.5s-3.7-1.6-3.7-3.5s1.7-3.5,3.7-3.5S28.7,23,28.7,25z"
                          />
                          <path
                            className="bell-icon__shell"
                            id="shell"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            fill="#FFFFFF"
                            stroke="#currentColor"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            d="M35.9,21.8c-1.2-0.7-4.1-3-3.4-8.7c0.1-1,0.1-2.1,0-3.1h0c-0.3-4.1-3.9-7.2-8.1-6.9c-3.7,0.3-6.6,3.2-6.9,6.9h0 c-0.1,1-0.1,2.1,0,3.1c0.6,5.7-2.2,8-3.4,8.7c-0.4,0.2-0.6,0.6-0.6,1v1.8c0,0.2,0.2,0.4,0.4,0.4h22.2c0.2,0,0.4-0.2,0.4-0.4v-1.8 C36.5,22.4,36.3,22,35.9,21.8L35.9,21.8z"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              ) : config.noticesType === 'static' ? (
                <div
                  className={`banner-inner weducWidgetDisplay_${noticesMainLoaded}`}
                >
                  <div className={toggled ? 'close-alert' : 'open'}>
                    <div className="bannerTextClose">
                      <button onClick={() => setToggled(true)}>
                        <span>{noticesBannerExpanded ? '' : ''}</span>
                      </button>
                    </div>
                    <div className="bannerText">
                      <WeducNotices
                        delay={5}
                        limit={1}
                        name={config.weducWebsitesID}
                        container={config.weducEntityID}
                        html={NoticeTemplate}
                        loadedEvent={setNoticesMainLoaded}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`banner-inner weducWidgetDisplay_${noticesMainLoaded}`}
                >
                  <Marquee speed={-0.1}>
                    <WeducNotices
                      delay={5}
                      limit={5}
                      name={config.weducWebsitesID}
                      container={config.weducEntityID}
                      html={NoticeTemplateTicker}
                      loaded={noticesMainLoaded}
                      loadedEvent={setNoticesMainLoaded}
                    />
                  </Marquee>
                </div>
              )}
            </div>
          </div>

          <div id="scrollHere" className="scrollHere"></div>
          <div id="mainContentWrapper" className="mainContentWrapper home">
            <div className="info-container">
              <div className="row intro-content">
                <div className="col-12 col-4-l no-padding">
                  {contentDataData[1].children[0].children[0][0] &&
                    contentDataData[1].children[0].children[0][0].value && (
                      <img
                        className="img-wrap"
                        src={
                          contentDataData[1].children[0].children[0][0].value
                        }
                        alt=""
                      />
                    )}
                </div>
                <div className="col-12 col-8-l no-padding">
                  <div className="brown">
                    <h1 className="intro-title-wrap">
                      {contentDataData[1].children[0].children[0][1] &&
                        contentDataData[1].children[0].children[0][1].value && (
                          <span className="title">
                            {
                              contentDataData[1].children[0].children[0][1]
                                .value
                            }
                          </span>
                        )}
                      <br></br>
                      {contentDataData[1].children[0].children[0][2] &&
                        contentDataData[1].children[0].children[0][2].value && (
                          <span className="subtitle">
                            {
                              contentDataData[1].children[0].children[0][2]
                                .value
                            }
                          </span>
                        )}
                    </h1>
                    <WigLoader
                      weducWebsitesID={config.weducWebsitesID}
                      weducContainer={config.weducContainerID}
                      weducEntity={config.weducEntityID}
                      contentDataData={contentDataData}
                      contentMetaData={contentMetaData}
                      contentDataArray="1"
                      contentDataChild="0"
                      customDataArray={
                        contentDataData[1].children[0].children[0][3].value
                      }
                      AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                      AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                      integratorID={config.integratorID}
                      integratorAccessToken={config.integratorAccessToken}
                      imageFormatting={
                        process.env.IMAGE_FORMATTING_SECRET ||
                        process.env.GATSBY_IMAGE_FORMATTING_SECRET
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="info-container">
              <div className="row">
                <div
                  className="col-12 col-6-m"
                  id="weducNewsfeedCarouselSiemaWrap"
                >
                  <div className="weduc-calendar-widget">
                    <h3>Diary Dates</h3>
                    <div className="widget-item">
                      <WeducDairyDates
                        weducAPIURL="https://weduc.accropress.com/smartfeed/"
                        limit={5}
                        loadedEvent={setCalendarEventsLoaded}
                        name={config.weducWebsitesID}
                        container={config.weducContainerID}
                        html={function CalendarTemplate(props) {
                          return (
                            <div
                              onClick={() =>
                                updateAndShowModal(
                                  props.data.title,
                                  props.data.description
                                )
                              }
                              className="sidebarDataItem"
                            >
                              <div className="calendar">
                                {props.data.start_date && (
                                  <>
                                    <div className="date">
                                      {moment(props.data.start_date).format(
                                        'DD'
                                      )}
                                    </div>
                                    <div className="month">
                                      {moment(props.data.start_date).format(
                                        'MMMM'
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                              <div>{props.data.title}</div>
                            </div>
                          );
                        }}
                        route={'/news-and-events/events'}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-6-m"
                  id="weducNewsfeedCarouselSiemaWrap"
                >
                  <div className="weduc-news-widget">
                    <h3>Latest News</h3>
                    <div className="widget-item">
                      <WeducNewsFeed
                        loadedEvent={setNewsfeedLoaded}
                        limit={5}
                        name={config.weducWebsitesID}
                        container={config.weducContainerID}
                        html={function NewsFeedTemplate(props) {
                          return (
                            <div
                              onClick={() =>
                                updateAndShowModal(
                                  props.data.title,
                                  props.data.contentHtml,
                                  props.data.postType
                                )
                              }
                              className="sidebarDataItem"
                            >
                              <div className="calendar">
                                {props.data.createdAt && (
                                  <>
                                    <div className="date">
                                      {moment(props.data.createdAt).format(
                                        'DD'
                                      )}
                                    </div>
                                    <div className="month">
                                      {moment(props.data.createdAt).format(
                                        'MMMM'
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>

                              <div>
                                {props.data.contentHtml
                                  .replace(/<\/p>]*>?/gm, ' ')
                                  .replace(/<[^>]*>?/gm, '')
                                  .substring(0, 30)}
                                {props.data.contentHtml
                                  .replace(/<\/p>]*>?/gm, ' ')
                                  .replace(/<[^>]*>?/gm, '').length > 30
                                  ? '...'
                                  : ''}
                              </div>
                            </div>
                          );
                        }}
                        route={'/news-and-events/latest-news'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider"></div>
            <div className="border"></div>
          </div>
          <Modal
            classNames={{ modal: 'AccroModal' }}
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            center
          >
            {modalData && (
              <>
                <div
                  dangerouslySetInnerHTML={{ __html: modalData.description }}
                ></div>
                {modalData.attachment && modalData.attachment.length > 0 && (
                  <ul className="AccroModal_Attachments">
                    {modalData.attachment.map((attachment) => {
                      return (
                        <li className="AccroModal_Attachments_Attachement">
                          <a
                            href={attachment.external_url}
                            title={attachment.title}
                            target="_blank"
                            rel="noopener noreferer"
                          >
                            {attachment.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                )}
                <div
                  dangerouslySetInnerHTML={{
                    __html: buildMediaContent(modalData.postType),
                  }}
                ></div>
              </>
            )}
          </Modal>
        </div>
      ) : (
        <div className="main-page-wrapper loading"></div>
      )}
    </Layout>
  );
}
